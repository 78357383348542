import s from './styles.module.scss';

const LandingLogo = () => {
  return (
    <div className={s.logo}>
      <svg
        width="196"
        height="28"
        viewBox="0 0 196 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.45801 18.4198V16.5838L10.84 14.0098C11.08 13.9018 11.308 13.8118 11.524 13.7398C11.74 13.6678 11.902 13.6198 12.01 13.5958C11.89 13.5718 11.716 13.5238 11.488 13.4518C11.272 13.3678 11.056 13.2718 10.84 13.1638L5.45801 10.5898V8.71777L13.342 12.5518V14.5858L5.45801 18.4198Z"
          fill="black"
        />
        <path d="M14.253 21.6418V19.9498H22.893V21.6418H14.253Z" fill="black" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24 1.7H4C2.72975 1.7 1.7 2.72975 1.7 4V24C1.7 25.2703 2.72975 26.3 4 26.3H24C25.2703 26.3 26.3 25.2703 26.3 24V4C26.3 2.72975 25.2703 1.7 24 1.7ZM4 0C1.79086 0 0 1.79086 0 4V24C0 26.2091 1.79086 28 4 28H24C26.2091 28 28 26.2091 28 24V4C28 1.79086 26.2091 0 24 0H4Z"
          fill="black"
        />
        <path
          d="M37.512 19.9998V10.0998H39.438V11.9898H39.906L39.438 12.4398C39.438 11.6478 39.672 11.0298 40.14 10.5858C40.608 10.1418 41.256 9.91983 42.084 9.91983C43.068 9.91983 43.854 10.2378 44.442 10.8738C45.03 11.4978 45.324 12.3438 45.324 13.4118V19.9998H43.38V13.6278C43.38 12.9798 43.206 12.4818 42.858 12.1338C42.51 11.7858 42.036 11.6118 41.436 11.6118C40.824 11.6118 40.338 11.7978 39.978 12.1698C39.63 12.5298 39.456 13.0578 39.456 13.7538V19.9998H37.512Z"
          fill="black"
        />
        <path
          d="M51.293 20.1798C50.489 20.1798 49.781 20.0238 49.169 19.7118C48.569 19.3878 48.107 18.9378 47.783 18.3618C47.459 17.7858 47.297 17.1138 47.297 16.3458V13.7538C47.297 12.9738 47.459 12.3018 47.783 11.7378C48.107 11.1618 48.569 10.7178 49.169 10.4058C49.781 10.0818 50.489 9.91983 51.293 9.91983C52.109 9.91983 52.817 10.0818 53.417 10.4058C54.017 10.7178 54.479 11.1618 54.803 11.7378C55.127 12.3018 55.289 12.9738 55.289 13.7538V15.5178H49.169V16.3458C49.169 17.0778 49.349 17.6358 49.709 18.0198C50.081 18.4038 50.615 18.5958 51.311 18.5958C51.875 18.5958 52.331 18.4998 52.679 18.3078C53.027 18.1038 53.243 17.8098 53.327 17.4258H55.253C55.109 18.2658 54.677 18.9378 53.957 19.4418C53.237 19.9338 52.349 20.1798 51.293 20.1798ZM53.417 14.3478V13.7358C53.417 13.0158 53.237 12.4578 52.877 12.0618C52.517 11.6658 51.989 11.4678 51.293 11.4678C50.609 11.4678 50.081 11.6658 49.709 12.0618C49.349 12.4578 49.169 13.0218 49.169 13.7538V14.2038L53.561 14.1858L53.417 14.3478Z"
          fill="black"
        />
        <path
          d="M57.8199 19.9998L56.2899 10.0998H57.9279L58.8459 16.6338C58.8939 16.9338 58.9359 17.2698 58.9719 17.6418C59.0199 18.0018 59.0559 18.3018 59.0799 18.5418C59.1039 18.3018 59.1399 18.0018 59.1879 17.6418C59.2479 17.2818 59.3019 16.9458 59.3499 16.6338L60.3759 10.0998H62.0139L63.0219 16.6338C63.0699 16.9458 63.1179 17.2878 63.1659 17.6598C63.2259 18.0198 63.2679 18.3198 63.2919 18.5598C63.3159 18.3078 63.3519 18.0018 63.3999 17.6418C63.4599 17.2698 63.5139 16.9338 63.5619 16.6338L64.4979 10.0998H66.0819L64.4979 19.9998H62.4459L61.4739 13.5378C61.4259 13.2018 61.3719 12.8478 61.3119 12.4758C61.2519 12.0918 61.2099 11.7858 61.1859 11.5578C61.1499 11.7858 61.1019 12.0918 61.0419 12.4758C60.9939 12.8478 60.9459 13.2018 60.8979 13.5378L59.8899 19.9998H57.8199Z"
          fill="black"
        />
        <path
          d="M66.7589 22.1418V20.4498H75.3989V22.1418H66.7589Z"
          fill="black"
        />
        <path
          d="M81.6739 20.1798C80.8579 20.1798 80.2099 19.9578 79.7299 19.5138C79.2499 19.0578 79.0099 18.4398 79.0099 17.6598L79.4239 18.1098H79.0099V19.9998H77.0839V6.85983H79.0279V9.68583L78.9739 11.9898H79.4239L79.0099 12.4398C79.0099 11.6598 79.2499 11.0478 79.7299 10.6038C80.2219 10.1478 80.8699 9.91983 81.6739 9.91983C82.6699 9.91983 83.4679 10.2558 84.0679 10.9278C84.6679 11.5998 84.9679 12.5118 84.9679 13.6638V16.4538C84.9679 17.5938 84.6679 18.4998 84.0679 19.1718C83.4679 19.8438 82.6699 20.1798 81.6739 20.1798ZM81.0079 18.4878C81.6439 18.4878 82.1419 18.3078 82.5019 17.9478C82.8619 17.5758 83.0419 17.0418 83.0419 16.3458V13.7538C83.0419 13.0578 82.8619 12.5298 82.5019 12.1698C82.1419 11.7978 81.6439 11.6118 81.0079 11.6118C80.3959 11.6118 79.9099 11.8038 79.5499 12.1878C79.2019 12.5598 79.0279 13.0818 79.0279 13.7538V16.3458C79.0279 17.0178 79.2019 17.5458 79.5499 17.9298C79.9099 18.3018 80.3959 18.4878 81.0079 18.4878Z"
          fill="black"
        />
        <path
          d="M86.9228 19.9998V18.2358H90.3968V11.8638H87.3728V10.0998H92.2868V18.2358H95.4728V19.9998H86.9228ZM91.1528 8.38983C90.7328 8.38983 90.3968 8.28183 90.1448 8.06583C89.8928 7.83783 89.7668 7.53783 89.7668 7.16583C89.7668 6.78183 89.8928 6.48183 90.1448 6.26583C90.3968 6.03783 90.7328 5.92383 91.1528 5.92383C91.5728 5.92383 91.9088 6.03783 92.1608 6.26583C92.4128 6.48183 92.5388 6.78183 92.5388 7.16583C92.5388 7.53783 92.4128 7.83783 92.1608 8.06583C91.9088 8.28183 91.5728 8.38983 91.1528 8.38983Z"
          fill="black"
        />
        <path
          d="M101.838 19.9998C100.938 19.9998 100.236 19.7538 99.7318 19.2618C99.2278 18.7698 98.9758 18.0858 98.9758 17.2098V11.8638H96.2398V10.0998H98.9758V7.30983H100.92V10.0998H104.79V11.8638H100.92V17.2098C100.92 17.8938 101.25 18.2358 101.91 18.2358H104.61V19.9998H101.838Z"
          fill="black"
        />
        <path
          d="M110.723 20.1798C109.907 20.1798 109.187 20.0298 108.563 19.7298C107.951 19.4178 107.477 18.9738 107.141 18.3978C106.817 17.8098 106.655 17.1258 106.655 16.3458V13.7538C106.655 12.9618 106.817 12.2778 107.141 11.7018C107.477 11.1258 107.951 10.6878 108.563 10.3878C109.187 10.0758 109.907 9.91983 110.723 9.91983C111.911 9.91983 112.865 10.2318 113.585 10.8558C114.305 11.4798 114.683 12.3318 114.719 13.4118H112.775C112.739 12.8478 112.541 12.4098 112.181 12.0978C111.821 11.7858 111.335 11.6298 110.723 11.6298C110.063 11.6298 109.541 11.8158 109.157 12.1878C108.773 12.5478 108.581 13.0638 108.581 13.7358V16.3458C108.581 17.0178 108.773 17.5398 109.157 17.9118C109.541 18.2838 110.063 18.4698 110.723 18.4698C111.335 18.4698 111.821 18.3138 112.181 18.0018C112.541 17.6898 112.739 17.2518 112.775 16.6878H114.719C114.683 17.7678 114.305 18.6198 113.585 19.2438C112.865 19.8678 111.911 20.1798 110.723 20.1798Z"
          fill="black"
        />
        <path
          d="M120.544 20.1618C119.728 20.1618 119.02 20.0058 118.42 19.6938C117.82 19.3818 117.358 18.9378 117.034 18.3618C116.71 17.7858 116.548 17.1078 116.548 16.3278V13.7718C116.548 12.9798 116.71 12.3018 117.034 11.7378C117.358 11.1618 117.82 10.7178 118.42 10.4058C119.02 10.0938 119.728 9.93783 120.544 9.93783C121.36 9.93783 122.068 10.0938 122.668 10.4058C123.268 10.7178 123.73 11.1618 124.054 11.7378C124.378 12.3018 124.54 12.9798 124.54 13.7718V16.3278C124.54 17.1078 124.378 17.7858 124.054 18.3618C123.73 18.9378 123.268 19.3818 122.668 19.6938C122.068 20.0058 121.36 20.1618 120.544 20.1618ZM120.544 18.4518C121.204 18.4518 121.714 18.2718 122.074 17.9118C122.434 17.5398 122.614 17.0118 122.614 16.3278V13.7718C122.614 13.0758 122.434 12.5478 122.074 12.1878C121.714 11.8278 121.204 11.6478 120.544 11.6478C119.896 11.6478 119.386 11.8278 119.014 12.1878C118.654 12.5478 118.474 13.0758 118.474 13.7718V16.3278C118.474 17.0118 118.654 17.5398 119.014 17.9118C119.386 18.2718 119.896 18.4518 120.544 18.4518Z"
          fill="black"
        />
        <path
          d="M126.495 19.9998V18.2358H129.969V11.8638H126.945V10.0998H131.859V18.2358H135.045V19.9998H126.495ZM130.725 8.38983C130.305 8.38983 129.969 8.28183 129.717 8.06583C129.465 7.83783 129.339 7.53783 129.339 7.16583C129.339 6.78183 129.465 6.48183 129.717 6.26583C129.969 6.03783 130.305 5.92383 130.725 5.92383C131.145 5.92383 131.481 6.03783 131.733 6.26583C131.985 6.48183 132.111 6.78183 132.111 7.16583C132.111 7.53783 131.985 7.83783 131.733 8.06583C131.481 8.28183 131.145 8.38983 130.725 8.38983Z"
          fill="black"
        />
        <path
          d="M136.442 19.9998V10.0998H138.368V11.9898H138.836L138.368 12.4398C138.368 11.6478 138.602 11.0298 139.07 10.5858C139.538 10.1418 140.186 9.91983 141.014 9.91983C141.998 9.91983 142.784 10.2378 143.372 10.8738C143.96 11.4978 144.254 12.3438 144.254 13.4118V19.9998H142.31V13.6278C142.31 12.9798 142.136 12.4818 141.788 12.1338C141.44 11.7858 140.966 11.6118 140.366 11.6118C139.754 11.6118 139.268 11.7978 138.908 12.1698C138.56 12.5298 138.386 13.0578 138.386 13.7538V19.9998H136.442Z"
          fill="black"
        />
        <path
          d="M145.903 22.1418V20.4498H154.543V22.1418H145.903Z"
          fill="black"
        />
        <path
          d="M161.538 19.9998C160.926 19.9998 160.392 19.8798 159.936 19.6398C159.48 19.3878 159.12 19.0338 158.856 18.5778C158.604 18.1218 158.478 17.5938 158.478 16.9938V8.62383H155.238V6.85983H160.422V16.9938C160.422 17.3778 160.53 17.6838 160.746 17.9118C160.962 18.1278 161.256 18.2358 161.628 18.2358H164.688V19.9998H161.538Z"
          fill="black"
        />
        <path
          d="M169.019 20.1798C167.987 20.1798 167.171 19.9158 166.571 19.3878C165.983 18.8478 165.689 18.1158 165.689 17.1918C165.689 16.2558 166.001 15.5238 166.625 14.9958C167.261 14.4558 168.131 14.1858 169.235 14.1858H171.989V13.2678C171.989 12.7278 171.821 12.3078 171.485 12.0078C171.149 11.7078 170.675 11.5578 170.063 11.5578C169.523 11.5578 169.073 11.6778 168.713 11.9178C168.353 12.1458 168.143 12.4518 168.083 12.8358H166.175C166.283 11.9478 166.691 11.2398 167.399 10.7118C168.119 10.1838 169.025 9.91983 170.117 9.91983C171.293 9.91983 172.223 10.2198 172.907 10.8198C173.591 11.4078 173.933 12.2118 173.933 13.2318V19.9998H172.043V18.1818H171.719L172.043 17.8218C172.043 18.5418 171.767 19.1178 171.215 19.5498C170.663 19.9698 169.931 20.1798 169.019 20.1798ZM169.595 18.6858C170.291 18.6858 170.861 18.5118 171.305 18.1638C171.761 17.8038 171.989 17.3478 171.989 16.7958V15.4998H169.271C168.767 15.4998 168.365 15.6378 168.065 15.9138C167.777 16.1898 167.633 16.5678 167.633 17.0478C167.633 17.5518 167.807 17.9538 168.155 18.2538C168.503 18.5418 168.983 18.6858 169.595 18.6858Z"
          fill="black"
        />
        <path
          d="M180.604 20.1798C179.788 20.1798 179.14 19.9578 178.66 19.5138C178.18 19.0578 177.94 18.4398 177.94 17.6598L178.354 18.1098H177.94V19.9998H176.014V6.85983H177.958V9.68583L177.904 11.9898H178.354L177.94 12.4398C177.94 11.6598 178.18 11.0478 178.66 10.6038C179.152 10.1478 179.8 9.91983 180.604 9.91983C181.6 9.91983 182.398 10.2558 182.998 10.9278C183.598 11.5998 183.898 12.5118 183.898 13.6638V16.4538C183.898 17.5938 183.598 18.4998 182.998 19.1718C182.398 19.8438 181.6 20.1798 180.604 20.1798ZM179.938 18.4878C180.574 18.4878 181.072 18.3078 181.432 17.9478C181.792 17.5758 181.972 17.0418 181.972 16.3458V13.7538C181.972 13.0578 181.792 12.5298 181.432 12.1698C181.072 11.7978 180.574 11.6118 179.938 11.6118C179.326 11.6118 178.84 11.8038 178.48 12.1878C178.132 12.5598 177.958 13.0818 177.958 13.7538V16.3458C177.958 17.0178 178.132 17.5458 178.48 17.9298C178.84 18.3018 179.326 18.4878 179.938 18.4878Z"
          fill="black"
        />
        <path
          d="M189.453 20.1618C188.745 20.1618 188.121 20.0478 187.581 19.8198C187.053 19.5918 186.633 19.2798 186.321 18.8838C186.021 18.4758 185.847 17.9958 185.799 17.4438H187.743C187.791 17.7678 187.965 18.0318 188.265 18.2358C188.565 18.4278 188.961 18.5238 189.453 18.5238H190.227C190.815 18.5238 191.259 18.4038 191.559 18.1638C191.859 17.9238 192.009 17.6058 192.009 17.2098C192.009 16.8258 191.871 16.5258 191.595 16.3098C191.331 16.0818 190.935 15.9318 190.407 15.8598L189.129 15.6618C188.073 15.4938 187.293 15.1938 186.789 14.7618C186.297 14.3178 186.051 13.6638 186.051 12.7998C186.051 11.8878 186.345 11.1858 186.933 10.6938C187.533 10.1898 188.415 9.93783 189.579 9.93783H190.263C191.295 9.93783 192.117 10.1778 192.729 10.6578C193.353 11.1258 193.707 11.7558 193.791 12.5478H191.847C191.799 12.2598 191.637 12.0258 191.361 11.8458C191.097 11.6658 190.731 11.5758 190.263 11.5758H189.579C189.015 11.5758 188.601 11.6838 188.337 11.8998C188.085 12.1038 187.959 12.4098 187.959 12.8178C187.959 13.1778 188.073 13.4478 188.301 13.6278C188.529 13.8078 188.883 13.9338 189.363 14.0058L190.677 14.2218C191.793 14.3778 192.609 14.6898 193.125 15.1578C193.653 15.6138 193.917 16.2798 193.917 17.1558C193.917 18.1038 193.605 18.8418 192.981 19.3698C192.369 19.8978 191.451 20.1618 190.227 20.1618H189.453Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default LandingLogo;
