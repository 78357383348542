import React from 'react';

const LandingMenuClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        fill="#000"
        d="M6.5 5.586l4.95-4.95 1.414 1.414L7.914 7l4.95 4.95-1.415 1.414-4.95-4.95-4.949 4.95-1.414-1.415 4.95-4.95-4.95-4.95L1.55.637 6.5 5.586z"
      ></path>
    </svg>
  );
};

export default LandingMenuClose;
